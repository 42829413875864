import fetch from "isomorphic-fetch"
import { notification } from "antd"
import Raven from "raven-js"
import history from "../history"

export function callApi(
  endpoint,
  method = "get",
  body,
  contentType = "application/json"
) {
  let headers = { "content-type": contentType, Authorization: "" }
  if (localStorage.getItem("access_token")) {
    headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`
  }

  return fetch(endpoint, {
    headers: headers,
    method,
    body: JSON.stringify(body)
  }).then(response => {
    if (!response.ok) {
      if (response.status === 401) {
        history.push("/logout")
        notification["error"]({
          message: "Error ",
          description: "Invalid token, please login again"
        })
      } else {
        notification["error"]({
          message: "Error ",
          description:
            "Error status: " + response.status + " " + method + " " + endpoint
        })
        Raven.captureException(response.status, {
          extra: method + " " + endpoint
        })
      }
      return Promise.reject(response)
    }
    if (response.status === 204) {
      return
    }
    if (contentType === "application/json") {
      return response.json()
    } else if (contentType === "arrayBuffer") {
      return response.arrayBuffer()
    } else {
      return response
    }
  })
}
