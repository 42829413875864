import React from "react"
import { Spin } from "antd"

export const LoadingView = () => (
  <div>
    Loading <Spin size="small" />
  </div>
)

export const ErrorView = props => <div>{props.title}: Error getting data!</div>
