import React from "react"
import PropTypes from "prop-types"
import { Select } from "antd"
import { callApi } from "../../libs/api"
import convertPropsToBody from "./Utils"
import { LoadingView, ErrorView } from "./SBViews"

export default class SBSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, error: false, list: [] }
  }

  componentDidMount() {
    this.loadData(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //only fetch if instructed by reloadCounter. stops race conditions
    if (this.props.reloadCounter !== nextProps.reloadCounter) {
      this.loadData(nextProps)
    } else {
      //this typically means you're doing an insert.
      //you need to reload if there is no blank option.
      //alternative idea? add a blank option for antd select
      if (this.props.value !== "" && nextProps.value === "") {
        this.loadData(nextProps)
      }
    }
  }

  loadData = props => {
    this.setState({ loading: true })
    let url = "/api/general/" + props.endPoint

    let httpType = "post"
    if (props.endPoint.charAt(0) === "/") {
      url = props.endPoint
      httpType = "get"
    }

    const body = convertPropsToBody(props)
    if (this.props.fields) {
      body.fields = this.props.fields
    } else {
      body.fields = [props.keyValue, props.displayValue]
    }

    callApi(url, httpType, httpType === "post" ? body : undefined)
      .then(data => {
        this.setState({
          loading: false,
          error: false,
          list: data
        })
      })
      .catch(err => {
        console.error(
          "Error: SBSelect -> callApi() -> url: " + url + " error: " + err
        )
        this.setState({
          loading: false,
          error: true,
          list: []
        })
      })
  }

  //onSelectChange
  handleChange = newValue => {
    // this.setState({ value: newValue })
    this.props.onChange(newValue)
  }

  render() {
    if (this.state.loading) {
      return <LoadingView />
    } else if (this.state.error) {
      return <ErrorView title={this.props.title} />
    } else {
      return (
        <Select
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          {...this.props}
          value={this.props.value ? this.props.value.toString() : ""}
          onChange={this.handleChange}
        >
          {this.props.blank && <Select.Option key={0}>* All</Select.Option>}
          {this.state.list.map(item => (
            <Select.Option
              key={item[this.props.keyValue]}
              value={item[this.props.keyValue].toString()}
            >
              {item[this.props.displayValue]}
            </Select.Option>
          ))}
          {this.props.children}
        </Select>
      )
    }
  }
}

SBSelect.defaultProps = {
  keyValue: "ID",
  displayValue: "name",
  value: "",
  style: { width: "100%" },
  orderBy: ["name"]
}

SBSelect.propTypes = {
  fields: PropTypes.array,
  endPoint: PropTypes.string.isRequired,
  joins: PropTypes.array,
  where: PropTypes.object,
  orderBy: PropTypes.array,
  //name of database field that is the value of the dropdown option
  keyValue: PropTypes.string,
  displayValue: PropTypes.string,
  value: PropTypes.any,
  //this is a hackish way to force a reload
  reloadCounter: PropTypes.number,
  //when the value is changed, action called up.
  onChange: PropTypes.func
}
