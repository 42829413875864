import auth0 from "auth0-js"
import { callApi } from "./api"

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: auth0Domain,
    clientID: auth0ClientId,
    redirectUri: `${window.location.origin}/callback`,
    audience: auth0Audience,
    responseType: "token id_token",
    scope: "openid",
  })

  login = () => {
    this.auth0.authorize()
  }

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult)
          resolve()
        } else if (err) {
          console.log(err)
          reject(err)
        }
      })
    })
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    )
    localStorage.setItem("access_token", authResult.accessToken)
    localStorage.setItem("id_token", authResult.idToken)
    localStorage.setItem("expires_at", expiresAt)
  }

  logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token")
    localStorage.removeItem("id_token")
    localStorage.removeItem("expires_at")
  }

  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"))
    return new Date().getTime() < expiresAt
  }

  getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token")
    return accessToken
  }

  getProfile = () => {
    return new Promise((resolve, reject) => {
      callApi("/api/users/profile")
        .then((profile) => resolve(profile))
        .catch((err) => reject(err))
    })
  }

  getAppVersion = () => {
    return new Promise((resolve, reject) => {
      callApi("/api/general/systemsetting/1")
        .then((systemsetting) => resolve(systemsetting))
        .catch((err) => reject(err))
    })
  }
}
