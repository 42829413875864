import { observable, action, makeObservable, runInAction } from "mobx"

class CommonStore {
  selectedKey = ""
  menuItems = []
  forecastYear = new Date().getFullYear()
  forecastCustomerID = null
  forecastPageNumber = 1
  forecastChartType = "line"

  updateSelectedKey = (key) => {
    this.selectedKey = key
  }

  setContextMenu = (moduleName) => {
    switch (moduleName) {
      case "sales":
        this.menuItems = [
          {
            title: "Dashboard",
            link: "/sales/dashboard",
            icon: "dashboard",
            accessLevel: 50,
          },
          {
            title: "Sales Orders",
            link: "/sales/list",
            icon: "customer-service",
            accessLevel: 30,
          },
          {
            title: "Forecasting",
            link: "/sales/forecasting",
            accessLevel: 30,
            icon: "rise",
          },
          {
            title: "Credit Memos",
            link: "/sales/creditmemo",
            icon: "interaction",
            accessLevel: 50,
            hide: [3]
          },
          {
            title: "Customer Management",
            link: "/sales",
            icon: "team",
            accessLevel: 50,
            subMenu: [
              {
                title: "Profile",
                link: "/sales/profile",
                icon: "user",
                accessLevel: 50,
              },
              {
                title: "Pricing",
                link: "/sales/pricing",
                icon: "wallet",
                accessLevel: 50,
              },
              {
                title: "Ship Tos",
                link: "/sales/shipto",
                icon: "profile",
                accessLevel: 50,
              },
            ],
          },
          {
            title: "Shipping",
            link: "/sales/shippinglist",
            icon: "right-square",
            accessLevel: 0,
            hide: [2,3]
          },
        ]
        break
      case "purchasing":
        this.menuItems = [
          {
            title: "Purchase Orders",
            link: "/purchasing/purchaseorder",
            icon: "profile",
            accessLevel: 0,
          },
        ]
        break
      case "bills":
        this.menuItems = [
          {
            title: "Bill Matching",
            link: "/bills/list",
            icon: "profile",
            accessLevel: 0,
          },
          {
            title: "Open Bills",
            link: "/bills/open",
            icon: "table",
            accessLevel: 0,
          },
        ]
        break
      case "operations":
        this.menuItems = [
          {
            title: "Dashboard",
            link: "/operations/dashboard",
            icon: "dashboard",
            accessLevel: 0,
            sav: true
          },
          {
            title: "Production Orders",
            link: "/operations/productionorder",
            icon: "profile",
            accessLevel: 0,
          },
          {
            title: "Blending",
            link: "/operations/",
            icon: "cluster",
            accessLevel: 0,
            hide: [2,3],
            subMenu: [
              {
                title: "Orders",
                link: "/operations/blending",
                icon: "filter",
                accessLevel: 0,
              },
              {
                title: "Inventory Availability",
                link: "/operations/blending/inventory",
                icon: "table",
                accessLevel: 0,
              },
            ],
          },
          {
            title: "Machine Data",
            link: "/operations/machinedata",
            icon: "folder-open",
            accessLevel: 0,
            hide: [3]
          },
        ]
        break
      case "inventory":
        this.menuItems = [
          {
            title: "Dashboard",
            link: "/inventory/dashboard",
            icon: "dashboard",
            accessLevel: 40,
          },
          {
            title: "Raw Items",
            link: "/inventory/rawitem",
            icon: "table",
            accessLevel: 60,
          },
          {
            title: "Finished Items",
            link: "/inventory/Finishedproduct",
            icon: "tags",
            accessLevel: 30,
          },
          {
            title: "Physical Inventory",
            link: "/inventory/physical",
            icon: "number",
            accessLevel: 60
          },
          {
            title: "Inventory",
            link: "/inventory",
            icon: "barcode",
            accessLevel: 50,
            subMenu: [
              {
                title: "Adjustments",
                link: "/inventory/adjustments",
                icon: "edit",
                accessLevel: 60,
              },
              {
                title: "Conversion",
                link: "/inventory/conversion",
                icon: "fork",
                accessLevel: 60,
              },
              {
                title: "Transfers",
                link: "/inventory/transfers",
                icon: "swap",
                accessLevel: 60,
              },
              {
                title: "Exceptions",
                link: "/inventory/exceptions",
                icon: "warning",
                accessLevel: 60,
              },
              {
                title: "History",
                link: "/inventory/history",
                icon: "database",
                accessLevel: 60,
                hide: [3]
              },
            ],
          },
          {
            title: "Manage Overhead",
            link: "/inventory/edit/overhead",
            icon: "dollar",
            accessLevel: 60,
          },
        ]
        break
      case "quality":
        this.menuItems = [
          {
            title: "Production View",
            icon: "tool",
            accessLevel: 0,
            link: "/quality/production",
          },
          {
            title: "Samples List",
            icon: "experiment",
            accessLevel: 0,
            link: "/quality/",
            subMenu: [
              {
                title: "Samples In Testing",
                link: "/quality/sample",
                icon: "experiment",
                accessLevel: 0,
              },
              {
                title: "Completed Samples",
                link: "/quality/completed",
                icon: "check-circle",
                accessLevel: 0,
              },
              {
                title: "Download Sample List",
                link: "/quality/download/list",
                icon: "export",
                accessLevel: 0,
              },
            ],
          },
          {
            title: "Bulk Update",
            icon: "save",
            accessLevel: 0,
            link: "/quality/bulkupdate",
          },
          {
            title: "Export Data",
            icon: "export",
            accessLevel: 0,
            link: "/quality/export",
          },
        ]
        break
      case "reports":
        this.menuItems = [
          {
            title: "Dashboard",
            link: "/reports/dashboard",
            icon: "dashboard",
            accessLevel: 80,
          },
          {
            title: "Sales",
            link: "/reports/sales",
            icon: "area-chart",
            accessLevel: 45,
            subMenu: [
              {
                title: "Open Orders",
                link: "/reports/sales/openorders",
                icon: "folder-open",
                accessLevel: 45,
              },
              {
                title: "Sales Detail - Product",
                link: "/reports/sales/detail",
                icon: "line-chart",
                accessLevel: 45,
              },
              {
                title: "Sales Detail - Customer",
                link: "/reports/sales/detail/customer",
                icon: "line-chart",
                accessLevel: 45,
              },
              {
                title: "Invoice History",
                link: "/reports/invoice/history",
                icon: "database",
                accessLevel: 90,
              },
              {
                title: "Forecast vs Actual",
                link: "/reports/sales/forecast",
                icon: "line-chart",
                accessLevel: 45,
              },
            ],
          },
          {
            title: "Operations",
            link: "/reports/operations",
            icon: "tool",
            accessLevel: 70,
            subMenu: [
              {
                title: "Production Log",
                link: "/reports/operations/productionlog",
                icon: "read",
                accessLevel: 70,
              },
              {
                title: "Recipe vs Actual",
                link: "/reports/operations/recipevsactual",
                icon: "experiment",
                accessLevel: 70,
              },
            ],
          },
          {
            title: "Accounting",
            link: "/reports/accounting",
            icon: "audit",
            accessLevel: 80,
            subMenu: [
              {
                title: "Aging Reports",
                link: "/reports/accounting/aging",
                icon: "dollar",
                accessLevel: 80,
              },
            ],
          },
          {
            title: "Financial",
            link: "/reports/financial",
            icon: "bank",
            accessLevel: 80,
            subMenu: [
              {
                title: "Committee Dashboard",
                link: "/reports/financial/dashboard",
                icon: "fund",
                accessLevel: 80,
              },
            ],
          },
        ]
        break
      case "settings":
        this.menuItems = [
          {
            title: "User Management",
            link: "/settings/users",
            icon: "user",
            accessLevel: 100,
            hide: [3]
          },
          {
            title: "System Settings",
            link: "/settings/system",
            icon: "setting",
            accessLevel: 100,
          },
          {
            title: "History Log",
            link: "/settings/history",
            icon: "hdd",
            accessLevel: 100,
            hide: [3]
          },
        ]
        break
      default:
        this.menuItems = []
    }
  }

  setForecastYear = (year) => {
    runInAction(() => {
      this.forecastYear = year
    })
  }

  setForecastCustomerID = (customerID) => {
    runInAction(() => {
      this.forecastCustomerID = customerID
    })
  }

  setForecastPageNumber = (pageNumber) => {
    runInAction(() => {
      this.forecastPageNumber = pageNumber
    })
  }

  setForecastChartType = (chartType) => {
    runInAction(() => {
      this.forecastChartType = chartType
    })
  }

  constructor() {
    makeObservable(this, {
      selectedKey: observable,
      menuItems: observable,
      forecastYear: observable,
      forecastCustomerID: observable,
      forecastPageNumber: observable,
      forecastChartType: observable,
      updateSelectedKey: action,
      setContextMenu: action,
    })
  }
}

export default new CommonStore()
