import moment from "moment"
import { useEffect, useRef } from 'react';

export const paritial = (fn, ...args) => fn.bind(null, ...args)

const _pipe = (f, g) => (...args) => g(f(...args))

export const pipe = (...fns) => fns.reduce(_pipe)

export const updateListById = (array, updatedItem, id = "ID") => {
  return array.map(item => {
    if (item[id] !== updatedItem[id]) {
      return item
    }
    return {
      ...item,
      ...updatedItem
    }
  })
}

export const transformList = (list, value, text) =>
  list.map(item => ({ value: item[value], text: item[text] }))

export const transformListFromString = text => {
  const list = text.split(",")
  return list.map((item, index) => ({ key: index, value: item, text: item }))
}

export const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

export const fixDate = dateIn => {
  if (dateIn) {
    const date = new Date(dateIn)
    return moment(
      new Date(date.getTime() - date.getTimezoneOffset() * -60000)
    ).format("MM/DD/YYYY")
  }
  return "-"
}

export const fixDateTime = dateIn => {
  if (dateIn) {
    const date = new Date(dateIn)
    return moment(new Date(date.getTime())).format("MM/DD/YYYY h:mm:ss a ")
  }
  return "-"
}


function* powerset(array, offset) {
  while (offset < array.length) {
    let first = array[offset++]
    for (let subset of powerset(array, offset)) {
      subset.push(first)
      yield subset
    }
  }
  yield []
}

export const findSum = (numbers, targetSum) => {
  const offset = numbers.length < 20 ? 0 : Math.round(targetSum)
  console.log({offset})
  for (let subset of powerset(numbers, offset)) {
    const total = subset.reduce((total, item) => total += +item.toFixed(2), 0)
    if (+total.toFixed(2) === targetSum) {
      return subset
    }
  }
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}