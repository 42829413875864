import React from "react"
import { inject, observer } from "mobx-react"
import { Router } from "react-router-dom"
import { Layout, Icon, Row, Col, Alert, message } from "antd"
import Routes from "../../routes"
import history from "../../history"
import SideMenu from "./SideMenu"
import Logo from "./Logo"
import UserMenu from "./UserMenu"
import ContextMenu from "./ContextMenu"
import SBSelect from "../SB/SBSelect"
import { callApi } from "../../libs/api"
const { Header, Content, Sider } = Layout
const app_version = process.env.REACT_APP_VERSION
const org = process.env.REACT_APP_ORG

class AppLayout extends React.Component {
  handleChange = (value) => {
    this.props.authStore.setBranchLocation(value)
  }

  render() {
    const { authenticated, user, appVersion, branchLocationID, disableLocation } =
      this.props.authStore
    console.log(`appVersion: ${appVersion}`)

    return (
      <Layout style={{ height: "112vh" }}>
        {authenticated && (
          <Sider
            trigger={null}
            collapsible
            collapsed={user.collapsed}
            theme="dark"
          >
            <Logo
              collapsed={user.collapsed}
              darkMode={Number(branchLocationID) === 1 ? true : false}
            />
            <SBSelect
              value={branchLocationID}
              endPoint="branchLocation"
              style={{ width: "98%" }}
              onChange={this.handleChange}
              disabled={disableLocation}
            />
            <SideMenu
              darkMode={Number(branchLocationID) === 1 ? true : false}
            />
          </Sider>
        )}
        <Layout>
          {authenticated && (
            <>
              {app_version !== appVersion && (
                <Alert
                  message="There is a new version of the application available, to refresh in Chrome press [ cmd + shift + r ] to update to the latest version. [ cmd + opt + r ] if using Safari."
                  type="warning"
                  showIcon
                  closable
                />
              )}
              <Header style={{ background: "#fff", padding: 0 }}>
                <Row>
                  <Col span={1}>
                    <Icon
                      className="trigger"
                      type={user.collapsed ? "menu-unfold" : "menu-fold"}
                      onClick={this.props.authStore.toggleCollapsed}
                    />
                  </Col>
                  <Col span={21}>
                    <ContextMenu />
                  </Col>
                  <Col span={2}>
                    <UserMenu />
                  </Col>
                </Row>
              </Header>
            </>
          )}
          {authenticated && (
            <div>
              <Row type="flex" justify="center">
                <Col>
                  <span
                    style={{
                      marginTop: 10,
                      fontWeight: "bold",
                      fontSize: 28,
                    }}                  
                  >
                    {Number(branchLocationID) === 1 && <span style={{ color: "orange" }}>Sylacauga</span>}
                    {Number(branchLocationID) === 2 && <span style={{ color: "blue" }}>{org === "pat" ? "Savannah - Coating" : "CRF AgriTech" } </span>}
                    {Number(branchLocationID) === 3 && <span style={{ color: "green" }}>Savannah - Blending</span>}
                  </span>
                </Col>
              </Row>
            </div>
          )}
          <Content style={{ margin: "5px 16px 0" }}>
            <Router history={history}>
              <div
                style={{
                  padding: 24,
                  background: "#fff",
                  minHeight: "95vh",
                }}
              >
                <Routes authenticated={authenticated} />
              </div>
            </Router>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default inject("authStore")(observer(AppLayout))
